import React from 'react'
import { Typography, Image, Space, Row, Col, Pagination } from 'antd';
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom';
import './index.css'

const DataAnalyticsBlock = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };

    const news = [
        {
            title: "Machine Learning Techniques in Big Data Analytics",
            date: '19 August 2024',
            url: 'datablog4cover.jpg',
            type: 'Data Analytics',
            route: '/blogs/data-analytics/machine-learning-techniques-in-big-data-analytics'
        },
        {
            title: "Understanding the 5 Vs of Big Data: A Casual Dive into Data Dynamics",
            date: '12 August 2024',
            url: 'datablog3cover.jpg',
            type: 'Data Analytics',
            route: '/blogs/data-analytics/understanding-5-vs-of-big-data'
        },
        {
            title: " Comparative Analysis of Big Data Tools: A Deep Dive into the Heavy Hitters",
            date: '06 August 2024',
            url: 'datablog2cover.jpg',
            type: 'Data Analytics',
            route: '/blogs/data-analytics/comparative-analysis-of-big-data-tools'
        },
        {
            title: "The Role of Big Data Analytics in Business Intelligence",
            date: '03 August 2024',
            url: 'datablog1cover.jpg',
            type: 'Data Analytics',
            route: '/blogs/data-analytics/the-role-of-big-data-analytics-in-business-intelligence'
        },
    ]
    return (
        <div>
            <div className="py-5" style={{ overflowX: 'hidden' }}>
                <div className='py-5 flex-col-center'>
                    {/* <SectionTop title='' description='News & Updates'/> */}
                    <div style={{ width: '93%' }}>
                        <Row gutter={[32, 32]}>
                            {
                                news?.map((news, n) => {
                                    return (
                                        <Col xs={24} sm={12} md={8}>
                                            <div className='px-2' key={'news-' + n}>
                                                <Link to={news?.route} className=" rounded-1 news-slider" onClick={scrollToTop}>
                                                    <div className='news-slider-image-box'>
                                                        <Image src={'/assets/' + news?.url} preview={false} alt='Mkaits' className='img-fluid one animation' style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                        <div className='two animation'>
                                                            <Image src={'/assets/plus.png'} alt='olive' />
                                                        </div>
                                                        <span className='tag px-3 py-1'>
                                                            <Typography.Text strong className='text-white'>
                                                                {
                                                                    news?.type
                                                                }
                                                            </Typography.Text>
                                                        </span>
                                                    </div>
                                                    <Space direction='vertical' className='align-center py-3 px-2' style={{ width: '100%' }}>
                                                        <Space direction='horizontal' size={10}>
                                                            <Space>
                                                                <ClockCircleOutlined
                                                                    style={{ transform: 'translateY(-4px)' }}
                                                                    className='brand-color'
                                                                />
                                                                <Typography.Text className='my-0' strong>{news?.date}</Typography.Text>
                                                            </Space>
                                                            <Space>
                                                                <UserOutlined
                                                                    style={{ transform: 'translateY(-4px)' }}
                                                                    className='brand-color'
                                                                />
                                                                <Typography.Text className='my-0' strong>By Admin</Typography.Text>
                                                            </Space>
                                                        </Space>
                                                        <Typography.Title className='w-100 my-0 text-center news-heading' level={4}>
                                                            {
                                                                news?.title
                                                            }
                                                        </Typography.Title>
                                                    </Space>
                                                </Link>
                                            </div>
                                        </Col>

                                    )
                                })
                            }
                        </Row>

                        {/* <Row gutter={[32, 32]} className='text-center mt-5'>
                            <Col span={24}>
                                <Space direction='vertical' className='pagin'>
                                    <Pagination defaultCurrent={1} total={50} />
                                </Space>
                            </Col>
                        </Row> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DataAnalyticsBlock