const dataAnalytics = [
  {
    title: "Machine Learning Techniques in Big Data Analytics",
    date: "19 August 2024",
    url: "datablog4cover.jpg",
    type: "Data Analytics",
    route:
      "/blogs/data-analytics/machine-learning-techniques-in-big-data-analytics",
  },
  {
    title:
      "Understanding the 5 Vs of Big Data: A Casual Dive into Data Dynamics",
    date: "12 August 2024",
    url: "datablog3cover.jpg",
    type: "Data Analytics",
    route: "/blogs/data-analytics/understanding-5-vs-of-big-data",
  },
  {
    title:
      " Comparative Analysis of Big Data Tools: A Deep Dive into the Heavy Hitters",
    date: "06 August 2024",
    url: "datablog2cover.jpg",
    type: "Data Analytics",
    route: "/blogs/data-analytics/comparative-analysis-of-big-data-tools",
  },
  {
    title: "The Role of Big Data Analytics in Business Intelligence",
    date: "03 August 2024",
    url: "datablog1cover.jpg",
    type: "Data Analytics",
    route:
      "/blogs/data-analytics/the-role-of-big-data-analytics-in-business-intelligence",
  },
];
const ai = [
  {
    title: "Impact of AI transformation on industries",
    date: "30 July 2024",
    url: "aiblog6cover.jpg",
    type: "Artificial Intelligence",
    route:
      "/blogs/artificial-intelligence/impact-of-ai-transformation-on-industries",
  },
  {
    title: "Ethical Considerations of AI",
    date: "27 July 2024",
    url: "aiblog5cover.jpg",
    type: "Artificial Intelligence",
    route: "/blogs/artificial-intelligence/ethical-considerations-of-ai",
  },
  {
    title: "Elevate Your Customer Experience with AI at Mkaits",
    date: "26 July 2024",
    url: "aiblog4cover.jpg",
    type: "Artificial Intelligence",
    route:
      "/blogs/artificial-intelligence/elevate-your-customer-experience-with-ai-at-mkaits",
  },
  {
    title: "Machine Learning vs. Deep Learning: What's the Difference?",
    date: "24 July 2024",
    url: "aiblog3cover.jpg",
    type: "Artificial Intelligence",
    route: "/blogs/artificial-intelligence/machine-learning-vs-deep-learning",
  },
  {
    title: "How to Begin Learning AI Skills?",
    date: "23 July 2024",
    url: "aiblog2cover.jpg",
    type: "Artificial Intelligence",
    route: "/blogs/artificial-intelligence/how-to-begin-learning-ai-skills",
  },
  {
    title: "What is coming in the world of AI in 2024?",
    date: "17 July 2024",
    url: "aiblog1cover.jpg",
    type: "Artificial Intelligence",
    route:
      "/blogs/artificial-intelligence/what-is-coming-in-the-world-of-ai-in-2024",
  },
];
const blockchain = [
  {
    title:
      "Exploring the World of Dapps: The Future of Decentralized Applications",
    date: "13 July 2024",
    url: "blog9cover.jpg",
    type: "Blockchain",
    route:
      "/blogs/blockchain/exploring-the-world-of-dapps-the-future-of-decentralized-applications",
  },
  {
    title: "DeFi: Reshaping the Financial Landscape",
    date: "09 July 2024",
    url: "blog8cover.jpg",
    type: "Blockchain",
    route: "/blogs/blockchain/defi-reshaping-the-financial-landscape",
  },
  {
    title: "The Transformative Potential of Decentralized Exchanges (DEX) ",
    date: "30 June 2024",
    url: "blog7cover.jpg",
    type: "Blockchain",
    route:
      "/blogs/blockchain/transformative-potential-of-decentralized-exchanges",
  },
  {
    title: "Decentralized Autonomous Organization (DAO)",
    date: "26 June 2024",
    url: "blog6cover.jpg",
    type: "Blockchain",
    route: "/blogs/blockchain/decentralized-autonomous-organization",
  },
];

export { dataAnalytics, ai, blockchain };
