import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./index.css";
import { ArrowRightOutlined, CaretDownFilled } from "@ant-design/icons";
import { Button, Typography, Image, Space } from "antd";
const { Text } = Typography;
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsNavShowing: false,
    };
  }
  showLargeNav = () => {
    var navbarSmall = document.getElementsByClassName("repla-navbar-small")[0];
    navbarSmall.setAttribute(
      "class",
      "repla-navbar-small secondary-bg animation repla-navbar-small-move"
    );
    var navbar = document.getElementsByClassName("repla-navbar")[0];
    navbar.setAttribute(
      "class",
      "repla-navbar secondary-bg repla-navbar-trans"
    );
    this.setState({ IsNavShowing: !this.state.IsNavShowing });
  };
  hideLargeNav = () => {
    var navbarSmall = document.getElementsByClassName("repla-navbar-small")[0];
    navbarSmall.setAttribute(
      "class",
      "repla-navbar-small secondary-bg animation"
    );
    var navbar = document.getElementsByClassName("repla-navbar")[0];
    navbar.setAttribute("class", "repla-navbar secondary-bg");
    this.setState({ IsNavShowing: !this.state.IsNavShowing });
  };
  render() {
    return (
      <>
        <div
          className="repla-navbar-container "
          style={{ position: "relative" }}
        >
          <div className="w-100">
            <div className="repla-navbar-small secondary-bg">
              <div className="repla-navbar-inner">
                <div className="repla-navbar-left">
                  <img
                    src={"/assets/logo.png"}
                    width={"100%"}
                    height={"100%"}
                  />
                </div>
                <div className="repla-navbar-right">
                  <div className="sp-cover" onClick={this.showLargeNav}>
                    <span className="sp sp-1 sp-1-click"></span>
                    <span className="sp sp-2 sp-2-click"></span>
                    <span className="sp sp-3 sp-3-click"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="repla-navbar">
            <div className="repla-navbar-inner">
              <div className="repla-navbar-left">
                <Image
                  src="/assets/logo.png"
                  width={"100%"}
                  preview={false}
                  className="one"
                />
                <img
                  src="/assets/close.png"
                  preview={false}
                  className="two"
                  onClick={this.hideLargeNav}
                />
              </div>
              <ul className="repla-navbar-middle" style={{ height: "100%" }}>
                <li className="items">
                  <NavLink to="/" className="nav-item">
                    <div className="align-center">
                      <ArrowRightOutlined className="nav-arrow animation text-dark" />
                      <Text strong className="my-0 item-name">
                        Home
                      </Text>
                    </div>
                  </NavLink>
                </li>
                <li className="items">
                  <NavLink to="" className="nav-item">
                    <div className="align-center">
                      <ArrowRightOutlined className="nav-arrow animation text-dark" />
                      <Text strong className="my-0 item-name">
                        Company
                      </Text>
                      <CaretDownFilled className="nav-arrowDown animation text-white" />
                    </div>
                  </NavLink>
                  <ul className="nav-item-menu secondary-bg">
                    <li>
                      <NavLink to="/about-us" className="p-2">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          About us
                        </Text>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/projects" className="p-2 ">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          Projects
                        </Text>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/mkaits-team" className="p-2 ">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          Team
                        </Text>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/faqs" className="p-2">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          FAQs
                        </Text>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="items">
                  <NavLink to="/services" className="nav-item">
                    <div className="align-center">
                      <ArrowRightOutlined className="nav-arrow animation text-dark" />
                      <Text strong className="my-0 item-name">
                        Services
                      </Text>
                      <CaretDownFilled className="nav-arrowDown animation text-white" />
                    </div>
                  </NavLink>
                  <ul className="nav-item-menu secondary-bg">
                    <li>
                      <NavLink to="/services/0" className="p-2">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          Software Development
                        </Text>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/services/1" className="p-2">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          Web Development
                        </Text>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/services/2" className="p-2">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          App Development
                        </Text>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/services/3" className="p-2">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          Game Development
                        </Text>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/services/4" className="p-2">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          Blockchain
                        </Text>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/services/5" className="p-2">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          Desktop App Development
                        </Text>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/services/6" className="p-2">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          Digital Marketing
                        </Text>
                      </NavLink>
                    </li>
                    {/* <li>
                                    <NavLink to='/services/7' className='p-2'>
                                        <span className='brand-bg sp animation'></span>
                                        <Text strong className='my-0 text-white'>UI/UX Design</Text>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/services/8' className='p-2'>
                                        <span className='brand-bg sp animation'></span>
                                        <Text strong className='my-0 text-white'>SEO</Text>
                                    </NavLink>
                                </li> */}
                  </ul>
                </li>
                <li className="items">
                  <NavLink to="/blogs" className="nav-item">
                    <div className="align-center">
                      <ArrowRightOutlined className="nav-arrow animation text-dark" />
                      <Text strong className="my-0 item-name">
                        Blogs
                      </Text>
                      <CaretDownFilled className='nav-arrowDown animation text-white' />
                    </div>
                  </NavLink>
                  <ul className="nav-item-menu secondary-bg">
                    <li>
                      <NavLink to="/blogs/blockchain" className="p-2">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          Blockchain
                        </Text>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="blogs/artificial-intelligence" className="p-2">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          Artificial Intelligence
                        </Text>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="blogs/data-analytics" className="p-2">
                        <span className="brand-bg sp animation"></span>
                        <Text strong className="my-0 text-white">
                          Data Analytics
                        </Text>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="items">
                  <NavLink to="/contact-us" className="nav-item">
                    <div className="align-center">
                      <ArrowRightOutlined className="nav-arrow animation text-dark" />
                      <Text strong className="my-0 item-name">
                        Contact Us
                      </Text>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
  componentDidMount(state) {
    var navbar = document.getElementsByClassName("repla-navbar")[0];
    var navbarLeft = document.getElementsByClassName("repla-navbar-left");
    var navbarSmall = document.getElementsByClassName("repla-navbar-small")[0];

    const responsive = () => { };
    responsive();
    window.addEventListener("resize", () => {
      responsive();
      scrol();
    });
    let upDown = window.pageYOffset;
    function scrol() {
      console.log(window.pageYOffset);
      if (window.innerWidth > 970) {
        if (window.pageYOffset > upDown) {
          navbarLeft[1].style.width = "100px";
          navbar.setAttribute(
            "class",
            "repla-navbar animation secondary-bg "
          );
        } else if (window.pageYOffset < 11) {
          navbarLeft[1].style.width = "140px";
          navbar.setAttribute("class", "repla-navbar animation transparent-bg");
        } else {
          navbarLeft[1].style.width = "100px";
          navbar.setAttribute("class", "repla-navbar animation secondary-bg");
        }
      } else {
        navbar.setAttribute("class", "repla-navbar");
        if (window.pageYOffset > upDown) {
          navbarLeft[0].style.width = "100px";
          navbarSmall.setAttribute(
            "class",
            "repla-navbar-small secondary-bg animation "
          );
        } else if (window.pageYOffset < 11) {
          navbarLeft[0].style.width = "140px";
          navbarSmall.setAttribute(
            "class",
            "repla-navbar-small transparent-bg animation"
          );
        } else {
          navbarLeft[0].style.width = "100px";
          navbarSmall.setAttribute(
            "class",
            "repla-navbar-small secondary-bg animation"
          );
        }
      }

    }
    scrol();
    window.onscroll = function () {
      scrol();
    };
  }
}
export default Navbar;
